import { baseUrl } from "../BaseUrl";
import { fetchWrapper } from "../helpers/fetchWrapper";
import { CommonServices } from "./common.service";

let url = `${baseUrl.DEVELOPMENT_API_URL}LicenseManagement/License`;

export class LicenseService extends CommonServices {
  constructor() {
    // call the super class constructor and pass in the name parameter
    super(url);
  }

  //override or use other methods

  GetLicenseDetail() {
    return fetchWrapper
      .get(url + `/GetLicenseDetail`)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  GetMaximumUsers() {
    return fetchWrapper
      .get(url + `/GetMaximumUsers`)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  GetCompanyDetail() {
    return fetchWrapper
      .get(url + `/GetCompanyDetail`)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  UpdateCompanyDetail(params) {
    return fetchWrapper
      .put(url + `/UpdateCompanyDetail`, params)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  GetCompanyLogo() {
    return fetchWrapper
      .get(url + "/GetCompanyLogoUrl")
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  UpdateCompanyLogo(params) {
    const formData = new FormData();
    formData.append("attachments", params.attachments);

    return fetchWrapper
      .postwithFile(url + "/UpdateCompanyLogo", formData)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  CheckDuplicateEmailID(EmailID, ExcludeID) {
    EmailID = encodeURIComponent(EmailID);
    return fetchWrapper
      .get(
        url + `/CheckDuplicateEmailID?EmailID=${EmailID}`
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  CheckDuplicatePhoneNo(PhoneNo, ExcludeID) {
    return fetchWrapper
      .get(
        url + `/CheckDuplicatePhoneNo?PhoneNo=${PhoneNo}`
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
